import React from 'react';
import { Formik, Form, Field } from 'formik';
import { registerSchema } from './FormSchema';

const RegisterForm = ({ details, setDetails, next }) => {
    return (
        <>
            <Formik
                initialValues={{
                    firstname: details.firstname,
                    lastname: details.lastname,
                    email: details.email,
                    phone: details.phone,
                    dob: details.dob,
                    password: details.password,
                    confirmPassword: details.confirmPassword
                }}
                validationSchema={registerSchema}
                onSubmit={(details) => {
                    setDetails(details);
                    next();
                }}>
                {({ errors, touched }) => (
                    <>
                        <Form>
                            <div className="form-group">
                                <label htmlFor="firstname">First Name</label>
                                <Field type="text" name="firstname" id="firstname" />
                                {errors.firstname && touched.firstname ? <div className="error">{errors.firstname}</div> : null}
                            </div>
                            <div className="form-group">
                                <label htmlFor="lastname">Last Name</label>
                                <Field type="text" name="lastname" id="lastname" />
                                {errors.lastname && touched.lastname ? <div className="error">{errors.lastname}</div> : null}
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email Address</label>
                                <Field type="text" name="email" id="email" />
                                {errors.email && touched.email ? <div className="error">{errors.email}</div> : null}
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone">Phone Number</label>
                                <Field type="text" name="phone" id="phone" />
                                {errors.phone && touched.phone ? <div className="error">{errors.phone}</div> : null}
                            </div>
                            <div className="form-group">
                                <label htmlFor="dob">Date of Birth</label>
                                <Field type="text" name="dob" id="dob" />
                                {errors.dob && touched.dob ? <div className="error">{errors.dob}</div> : null}  
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <Field type="text" name="password" id="password" />
                                {errors.password && touched.password ? <div className="error">{errors.password}</div> : null}  
                            </div>
                            <div className="form-group">
                                <label htmlFor="confirmPassword">Confirm Password</label>
                                <Field type="text" name="confirmPassword" id="confirmPassword" />
                                {errors.confirmPassword && touched.confirmPassword ? <div className="error">{errors.confirmPassword}</div> : null}  
                            </div>                   
                            <button className="btn btn--white btn--icon" type="submit">
                                Next
                            </button>
                            {/* {error !== "" && <p>{error}</p>} */}
                        </Form>
                    </>
                )}
                </Formik>
        </>      
    );
}

export default RegisterForm;