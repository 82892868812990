import React from 'react';

const Confirmation = ({ email }) => {
    return (
        <div>
            Thank you for signing up with PT Sphere. We have sent a confirmation email to {email}
        </div>
    );
}

export default Confirmation;