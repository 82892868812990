import React from 'react';
import { Elements } from 'react-stripe-elements';
import PaymentForm from './PaymentForm';

const PaymentFormContainer = (props) => {
    return (
        <Elements>
            <PaymentForm registerUser={(token) => props.registerUser(token)} />
        </Elements>
    )
}

export default PaymentFormContainer;