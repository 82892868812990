import React, { useState } from 'react';
import {StripeProvider} from 'react-stripe-elements';
import './App.css';
import RegisterForm from './RegisterForm';
import PaymentFormContainer from './PaymentFormContainer';
import Confirmation from './Confirmation';

function App() {
  const [step, setStep] = useState(1);
  const [error, setError] = useState("");
  const [details, setDetails] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    dob: "",
    password: "",
    confirmPassword: ""
  });

  const registerUser = async (token) => {
    // fetch('http://localhost:3000/account', {
      // fetch('https://ptsphere-api.herokuapp.com/account', {
      fetch('https://api.ptsphere.com/account', {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: new Headers({
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          }),
          body: JSON.stringify({...details, token})
      })
      .then(response => response.json())
      .then(response => {
        if(response.status === 200) {
          setStep(3);
        } else {
          if(response.message.code === 11000) {
            setError("An account already exists with this email address.");
          } else {
            setError("There was a problem registering. Please try again.");
          }
        }
          console.log(response);
      })
      .catch(err => {
          console.log(err);
      })
  }

  return (
    <div className="form-container">
      <StripeProvider apiKey="pk_test_wZLYPSrYJlnUlWNAqw1eCc4p">
        <>
        {step === 1 &&
          <RegisterForm details={details} setDetails={setDetails} next={() => setStep(2)} />
        }
        {step === 2 && 
          <PaymentFormContainer registerUser={(token) => registerUser(token)} />
        }
        {step === 3 && 
          <Confirmation email={details.email} />
        }
        </>
      </StripeProvider>
      {error && <p className="form-error">{error}</p>}
    </div>
  );
}

export default App;
