import React from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';

const PaymentForm = (props) => {

    const onSubmit = (e) => {
        e.preventDefault();

        if(props.stripe) {
            props.stripe
                .createToken()
                .then((payload) => {
                    console.log(payload);
                    props.registerUser(payload.token);
                });
        } else {
            alert("Stripe.js has not loaded yet.");
        }
    }

    return (
        <>
            <CardElement hidePostalCode={true} />
            <button className="btn" onClick={(e) => onSubmit(e)}>Register</button>
        </>
    );
}

export default injectStripe(PaymentForm);