import * as Yup from 'yup';

export const registerSchema = Yup.object().shape({
    firstname: Yup.string()
                  .required('Please provide your first name.'),
    lastname: Yup.string()
                 .required('Please provide your last name.'),
    email: Yup.string()
              .email('Please enter a valid email address.')
              .required('Please provide your email address.'),
    phone: Yup.string()
              .required('Please enter your phone number.'),
    dob: Yup.string()
            .required('Please enter your date of birth.'),
    password: Yup.string()
            .required('No password provided.') 
            .min(8, 'Password is too short - should be 8 chars minimum.'),
    confirmPassword: Yup.string()
            .required('No password provided.') 
            .min(8, 'Confirm Password is too short - should be 8 chars minimum.')
});